@font-face {
    font-family: 'Sans';
    src: url('../fonts/PTS55F.ttf');
}

@font-face {
    font-family: 'Sans Italic';
    src: url('../fonts/PTS56F.ttf');
}

@font-face {
    font-family: 'Sans Bold';
    src: url('../fonts/PTS75F.ttf');
}

@font-face {
    font-family: 'Sans Bold Italic';
    src: url('../fonts/PTS76F.ttf');
}

p, h6 {
    font-family: Sans !important;
    font-weight: 500;
}

h1, h2, h3, h4, h5, span, a, .sans-bold {
    font-family: Sans !important;
    font-weight: 800 !important;
}

a {
    cursor: pointer;
}

.bg-grey3 {
    background-color: rgba(128, 128, 128, 0.3);
}

.bg-grey2 {
    background-color: rgba(128, 128, 128, 0.12);
}

.bg-grey4 {
    background-color: rgba(128, 128, 128, 0.75);
}

.mr-5 {
    margin-right: 2.35rem !important;
}

@media (min-width: 768px) {
    .w-md-750 {
        width: 750px;
    }
    .w-md-40p {
        width: 40% !important;
    }
    .w-md-25p {
        width: 25% !important;
    }
}

.w-300 {
    width: 300px;
}

.w-180 {
    width: 180px;
}

.w-120 {
    width: 120px;
}

.w-40 {
    width: 40px;
}

.w-30 {
    width: 30px;
}

.ul-check li::marker {
    content: '✓';
    color: green;
}

li span {
    position: relative;
    left: 10px;
}

.t1 {
    text-align: center;
}

.border-bottom-success {
    border-bottom: 2px solid #89bd24;
}

.my-modal {
    min-width: 50%;
}